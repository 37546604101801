import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import '../styles/global.scss'
import '../styles/_core.scss'
import Button from '../components/Button/Button'
import { SEO } from '../components/seo'
const NotFoundPage = ({ data, location }) => {
    return (
        <Layout location={location}>
            <section className="mt-20">
                <div className="container">
                    <div className="px-10 md:px-28 lg:px-44 xl:px-72">
                        <h1 className="text-[#3165F5] text-center font-bold font-['Poppins'] text-7xl md:text-9xl xl:text-[200px]">
                            404
                        </h1>
                        <h1 className="sec-title text-[#3165F5] text-center">
                            Page Not Found
                        </h1>
                        <p className="sec-text text-center border-b-2 pb-10 pt-4">
                            This is a completely custom 404 error page. It seems
                            that page you are looking for no longer exists.
                        </p>
                        <div className="w-full flex items-center">
                            <Link to={`/`} className="mt-10 mx-auto">
                                <Button>Back HomePage</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
export default NotFoundPage
export const Head = () => <SEO title={'Page not found :( | Saigon Digital'} />
